<script>
import _ from "lodash";
import getStatus from "@/services/survey/getStatus";
import TopStrip, {TOP_STRIP_MODES} from "@/components/TopStrip.vue";
import ExcapPyramidImage from "@/assets/img/updated_excap_landing.png";

export default {
  components: {
    TopStrip
  },
  data() {
    return {
      bgImg: require('@/assets/img/bg.png'),
      insights: require('@/assets/img/Insights.svg'),
      logo: require('@/assets/img/kearney-logo-black.svg'),
      dropdownOpen: false,
      isAuthenticated: !!localStorage.getItem('accessToken'), // Initial check for token
      pillarInfo: [],
      TOP_STRIP_MODES,
      ExcapPyramidImage,
      _: _
    }
  },

  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: 'calc(100vh - 68px)',
        padding: '20px',
      }
    },
    getUserToken() {
      return localStorage.getItem('accessToken');
    },
    categories(){
      return _.map(this.pillarInfo, v => {
        return {
          'category': v.pillarName,
          'count': _.reduce(v.segments, (acc, v1)=>{
            acc += _.toNumber(v1.static_questions);
            return acc;
          }, 0)
        }
      })
    }
  },

  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    logout() {
      localStorage.clear(); // Clear local storage
      this.isAuthenticated = false; // Update reactive property
      this.$router.push({name: "home"}); // Redirect to home page
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
    navigate() {
      this.$router.push({name: "SurveyDashboard"});
    }
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  watch: {
    getUserToken(newValue) {
      this.isAuthenticated = !!newValue;
    }
  },
  async created() {
    // await getStatus().then((res)=>{
    //   if (res && !res.error){
    //     this.pillarInfo = res;
    //   }
    // })
  }
}
</script>
<template>
  <div class="top-strip">
    <TopStrip :mode="TOP_STRIP_MODES.PAGE"/>
  </div>
  <div :style="backgroundStyle" class="page-wrapper">
    <div class="landing-content">
      <div class="topContainer">
        <div class="text-white font-avenir text-4xl font-bold leading-[72px] text_0 pb-4"> ExCap Survey</div>
        <div class="survey-intro-text text_1 text-white text-xl leading-[26px]">
          The global benchmarking survey for excellence in capital projects
        </div>
        <div class="survey-intro-text text_2 text-white text-xl leading-[26px]">
          Gain valuable insights into the latest capital project management trends and see how your company measures up against the best
        </div>
        <div class="flex mt-3 text_3">
          <div class="text-white text-xl leading-[26px]">
            Time to Complete:
          </div>
          <div class="text-white font-avenir text-xl font-bold pl-1">~45 minutes</div>
        </div>
        <div>
          <button @click="navigate" class="surveyBtn">Take the survey now</button>
        </div>
      </div>
      <div class="landing-img" :style="`background-image: url(${ExcapPyramidImage});`">
      </div>
    </div>

<!--    <div class="bottomContainer flex mt-10 flex-wrap">-->
<!--      <div class="box" v-for="(option, idx) in categories" :key="idx">-->
<!--        <img :src="insights"/>-->
<!--        <p>{{ option.category }}</p>-->
<!--        <p>Total Questions - {{ option.count }}</p>-->
<!--      </div>-->
<!--    </div>-->
    <div class="confidentiality-statement">
      <div class="confidentiality-statement-header">
        CONFIDENTIALITY STATEMENT:
      </div>
      <div class="confidentiality-statement-sub">
        Your individual responses and results will be confidential.
      </div>
    </div>
  </div>
</template>
<style>
/* Prevent scrolling */
html, body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Disable scrolling */
  font-family: Arial, sans-serif;
  height: 100%; /* Full viewport height */
  width: 100%; /* Full viewport width */
}

/* Page wrapper for flex layout */
.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space between elements */
  align-items: flex-start;
  color: #fff;
  text-align: left;
  width: 100%;
  height: 100vh; /* Full viewport height */
  position: relative; /* Ensures absolute elements anchor correctly */
}

/* Top container (ExCap Survey text box) */
.landing-img {
  height: 35rem;
  width: 39rem;
  background-size: cover;
  background-position: center;
  z-index: 1;
  position: absolute;
  right: 60px;
  top: 100px;
}
.topContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 90%;
  max-width: 985px;
  padding: 30px 20px;
  border-radius: 24px;
  background: linear-gradient(124deg, rgba(0, 0, 0, 0.36) 5.12%, rgba(0, 0, 0, 0.07) 102.99%);
  backdrop-filter: blur(4px);
  margin: 20px;
}

/* Survey intro text */
.survey-intro-text {
  width: 100%;
  max-width: 600px;
}

/* Pyramid image container anchored at the bottom */
.landing-img {
  height: auto;
  max-height: 35rem;
  max-width: 39rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 10%; /* Anchored at the bottom */
  right: 10%;
}

/* Icons or additional bottom content */
.icons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  position: absolute;
  bottom: 5%; /* Anchored at the bottom */
  left: 20px;
}

/* Individual icon styles */
.icon {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.15); /* Icon placeholder background */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: white;
}

/* Survey button */
.surveyBtn {
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(99, 225, 225, 0.25);
  color: #8737E1;
  font-size: 1rem;
  font-weight: 700;
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 12px 24px;
  margin-top: 20px;
}

/* Confidentiality statement */
.confidentiality-statement {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 2%; /* Anchored at the bottom */
  left: 20px;
}

.confidentiality-statement-header {
  font-weight: 700;
  font-size: 1rem;
}

.confidentiality-statement-sub {
  font-weight: 400;
  font-size: 0.9rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .landing-content {
    flex-direction: column;
    align-items: center;
  }

  .landing-img {
    position: absolute; /* Keep it anchored */
    bottom: 0; /* Fixed at the bottom */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    max-width: 39rem;
    max-height: 80rem;
  }

  .icons-container {
    bottom: 3%;
    left: 5%;
  }

  .topContainer {
    align-items: center;
    text-align: center;
  }

  .survey-intro-text {
    text-align: center;
    max-width: 100%;
  }

  .surveyBtn {
    font-size: 0.8rem;
    padding: 8px 16px;
  }

  .confidentiality-statement {
    font-size: 0.8rem;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 10%;
  }
  .text_0 {
    line-height: 1px !important;
  }
  .text_2{
    line-height: 24px !important;
  }
  /* .text_3 {
    display: none !important;
  } */
}

@media (max-width: 768px) {
  .landing-content {
    flex-direction: column;
    align-items: center;
  }

  .landing-img {
    position: absolute; /* Keep it anchored */
    bottom: 0; /* Fixed at the bottom */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    max-width: 39rem;
    max-height: 60rem;
  }

  .icons-container {
    bottom: 3%;
    left: 5%;
  }

  .topContainer {
    align-items: center;
    text-align: center;
  }

  .survey-intro-text {
    text-align: center;
    max-width: 100%;
  }

  .surveyBtn {
    font-size: 0.8rem;
    padding: 8px 16px;
  }

  .confidentiality-statement {
    font-size: 0.8rem;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 10%;
  }
  .text_0 {
    line-height: 1px !important;
  }
  .text_3 {
    display: none !important;
  }

}

@media (max-width: 480px) {
  html, body {
    height: 100%; /* Ensure full height */
    overflow: hidden; /* Disable scrolling */
  }

  .page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Top and bottom alignment */
    height: 100vh; /* Full viewport height */
  }

  .topContainer {
    width: 100%;
    margin: 0;
    /* padding: 15px;
    width: 100%;
    max-width: 90%;
    margin: 10px auto; */
    display:block;
  }

  .landing-img {
    position: absolute; /* Keep it anchored */
    bottom: 0; /* Fixed at the bottom */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    max-width: 22rem;
    max-height: 48rem;
  }

  .surveyBtn {
    font-size: 0.7rem;
    padding: 6px 12px;
  }

  .icons-container {
    position: absolute; /* Fixed near the bottom */
    bottom: 5%;
    left: 10px;
    display: flex;
    gap: 10px;
  }

  .confidentiality-statement {
    position: absolute; /* Anchor above the image */
    bottom: 15%;
    left: 10px;
    font-size: 0.8rem;
  }

  .confidentiality-statement-header {
    font-size: 0.8rem;
  }

  .confidentiality-statement-sub {
    font-size: 0.7rem;
  }
  .ts-brand-logo {
    font-size: 27px !important;
    font-weight: 400;
    padding-left: 1rem;
  }
  .text_0 {
    line-height: 11px !important;
  }
  /* .text_1 {
    font-size: 14px !important;
  } */
  /* .text_2 {
    display: none;
  } */
  .text_3 {
    display: none !important;
  }
  .survey-intro-text {
    width: 100%;
  }
}

@media (max-width: 375px) {
  html, body {
    height: 100%; /* Ensure full height */
    overflow: hidden; /* Disable scrolling */
  }

  .page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Top and bottom alignment */
    height: 100vh; /* Full viewport height */
  }

  .topContainer {
    width: 100%;
    margin: 0;
    /* padding: 15px;
    width: 100%;
    max-width: 90%;
    margin: 10px auto; */
    display:block;
  }

  .landing-img {
    position: absolute; /* Keep it anchored */
    bottom: 0; /* Fixed at the bottom */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    max-width: 22rem;
    max-height: 31rem;
  }

  .surveyBtn {
    font-size: 0.7rem;
    padding: 6px 12px;
  }

  .icons-container {
    position: absolute; /* Fixed near the bottom */
    bottom: 5%;
    left: 10px;
    display: flex;
    gap: 10px;
  }

  .confidentiality-statement {
    position: absolute; /* Anchor above the image */
    bottom: 15%;
    left: 10px;
    font-size: 0.8rem;
  }

  .confidentiality-statement-header {
    font-size: 0.8rem;
  }

  .confidentiality-statement-sub {
    font-size: 0.7rem;
  }
  .ts-brand-logo {
    font-size: 27px !important;
    font-weight: 400;
    padding-left: 1rem;
  }
  .text_0 {
    line-height: 11px !important;
  }
  .text_1 {
    font-size: 14px !important;
  }
  .text_2 {
    display: none;
  }
  .text_3 {
    display: none !important;
  }
  .survey-intro-text {
    width: 100%;
  }
}
</style>

<script>
import _ from "lodash";
import { useSnackbar } from "vue3-snackbar";
import createNewUser from "@/services/survey/createNewUser";
import router from "@/router";
import Loader from "@/components/Loader.vue";

export default {
  name: "CreateUser",
  components: { Loader },
  data() {
    return {
      name: "",
      companyName: "",
      email: "",
      mobileNumber: "",
      password: "",
      confirmPassword: "",
      invitedBy: "",
      loading: false,
      snackbar: useSnackbar(),
    }
  },
  methods: {
    async handleSubmit() {
      // Validate all required fields
      if (!this.validateForm()) {
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.snackbar.add({
          type: 'error',
          text: 'Passwords do not match.'
        });
        return;
      }

      this.loading = true;
      const resp = await createNewUser({
        name: this.name,
        companyName: this.companyName,
        email: this.email,
        mobileNumber: this.mobileNumber,
        password: this.password,
        confirmPassword: this.confirmPassword,
        invitedBy: this.invitedBy || null,
        typeOfUser: 'created'
      });
      debugger;
      if (!resp.error) {
        this.snackbar.add({
          type: 'success',
          text: 'User Added!'
        });
        localStorage.clear(); 
        await router.push({ name: "login" });
      } else {
        this.snackbar.add({
          type: 'error',
          text: 'An Error Occurred.'
        });
      }
      this.loading = false;
    },
    validateForm() {
      // Mandatory fields except invitedBy
      const requiredFields = [
        { field: 'name', label: 'Full Name' },
        { field: 'companyName', label: 'Company Name' },
        { field: 'email', label: 'Email' },
        { field: 'password', label: 'Password' },
        { field: 'confirmPassword', label: 'Confirm Password' }
      ];

      for (let { field, label } of requiredFields) {
        if (!this[field]) {
          this.snackbar.add({
            type: 'error',
            text: `${label} is required.`
          });
          return false;
        }
      }

      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        this.snackbar.add({
          type: 'error',
          text: 'Please enter a valid email address.'
        });
        return false;
      }

      // Mobile number validation (basic 10-digit)
      // const phoneRegex = /^[0-9]{10}$/;
      // if (!phoneRegex.test(this.mobileNumber)) {
      //   this.snackbar.add({
      //     type: 'error',
      //     text: 'Please enter a valid 10-digit mobile number.'
      //   });
      //   return false;
      // }

      // Password match validation
      if (this.password !== this.confirmPassword) {
        this.snackbar.add({
          type: 'error',
          text: 'Passwords do not match.'
        });
        return false;
      }

      return true;
    }
  },
  created() {
    // this.token = _.get(this.$route, "query.token");
    // // Optional: Pre-fill invited by from query parameter if available
    // this.invitedBy = _.get(this.$route, "query.invitedBy", "");
  }
}
</script>

<template>
  <div class="flex items-center h-screen">
    <Loader :loading="loading" :add-margin-for-left-nav="false" />
    <div class="w-1/2 h-screen relative flex justify-center items-center">
      <img :src="require('@/assets/img/kearney-logo-black.svg')" class="p-3" alt="logo"
           style="position: absolute; top: 10px; left: 10px;" />
      <div class="flex flex-col items-center">
        <h2 class="font-bold text-3xl">User Details</h2>
        <p class="text-md text-gray-500 my-5">
          Setup your new account.
        </p>
        <div style="border: 1px solid #a0186a" class="w-[700px] p-3 rounded-lg">
          <div class="grid grid-cols-2 gap-3">
            <!-- Name -->
            <div style="border: 1px solid #a0186a" class="w-full">
              <input class="w-full h-full p-3" placeholder="Full Name *" type="text" v-model="name" required/>
            </div>
            
            <!-- Company Name -->
            <div style="border: 1px solid #a0186a" class="w-full">
              <input class="w-full h-full p-3" placeholder="Company Name *" type="text" v-model="companyName" required/>
            </div>
            
            <!-- Email -->
            <div style="border: 1px solid #a0186a" class="w-full">
              <input class="w-full h-full p-3" placeholder="Email *" type="email" v-model="email" required/>
            </div>
            
            <!-- Mobile Number -->
            <div style="border: 1px solid #a0186a" class="w-full">
              <input class="w-full h-full p-3" placeholder="Mobile Number" type="tel" v-model="mobileNumber"/>
            </div>
            
            <!-- Password -->
            <div style="border: 1px solid #a0186a" class="w-full">
              <input class="w-full h-full p-3" placeholder="Set Password *" type="password" v-model="password" required/>
            </div>
            
            <!-- Confirm Password -->
            <div style="border: 1px solid #a0186a" class="w-full">
              <input class="w-full h-full p-3" placeholder="Confirm Password *" type="password" v-model="confirmPassword" required/>
            </div>
            
            <!-- Invited By (optional) -->
            <div style="border: 1px solid #a0186a" class="w-full col-span-2">
              <input class="w-full h-full p-3" placeholder="Invited By" type="text" v-model="invitedBy"/>
            </div>
            
            <!-- Submit Button -->
            <div style="color: #FFFFFF" class="col-span-2 flex w-full text-center gap-3 justify-end">
              <button class="bg-black text-white px-3 py-3 rounded-lg mt-5" @click="handleSubmit">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right side image section remains the same -->
    <div class="w-1/2 h-screen relative">
      <img :src="require('@/assets/img/landing.png')" alt="Image" class="w-full h-screen object-cover" />
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="lvp-intro">
          <div class="lvp-intro-headline">ExCap Survey</div>
          <div class="lvp-intro-sub-headline">Your Voice, Our Vision—Shape the Future with Every Survey!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Existing styles remain the same */
.privacy-statement {
  color: #a0186a;
  text-decoration: underline;
}

.lvp-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFFFFF;
  width: 464px;
  text-align: center;
}

.lvp-intro-headline {
  font-size: 60px;
  font-weight: 800;
  line-height: 72px;
}

.lvp-intro-sub-headline {
  font-size: 32px;
  font-weight: 400;
  line-height: 51px;
}
</style>
<template>
  <div class="top-strip">
    <TopStrip :mode="TOP_STRIP_MODES.PAGE" />
  </div>
  <div class="flex h-screen bg-gray-100">
    <aside class="w-1/6 bg-white shadow-lg">
      <nav class="flex flex-col p-4 space-y-4">
        <button 
          class="p-2 rounded text-left text-gray-700 hover:bg-gray-200 flex items-center"
          @click="goBack">
          <span class="text-purple-500 mr-2">&lt;&lt;</span> Back
        </button>

        <button v-for="menuItem in menuItems" :key="menuItem.name" @click="selectMenu(menuItem.name)"
          :class="{ 'button-settings text-white': selectedMenu === menuItem.name, 'text-gray-700 hover:bg-gray-200': selectedMenu !== menuItem.name }"
          class="p-2 rounded text-left">
          {{ menuItem.label }}
        </button>
      </nav>
    </aside>

    <main class="flex-1 p-6">
      <div v-if="selectedMenu === 'changePassword'">
        <h2 class="text-2xl font-semibold mb-4">Change Password</h2>
        <form @submit.prevent="changePassword" class="space-y-4 max-w-md">
          <div>
            <label for="current-password" class="block text-sm font-medium text-gray-700">Current Password</label>
            <input type="password" id="current-password" v-model="currentPassword"
              class="mt-1 block w-full p-2 border border-gray-300 rounded" placeholder="Enter your current password"
              required />
          </div>
          <div>
            <label for="new-password" class="block text-sm font-medium text-gray-700">New Password</label>
            <input type="password" id="new-password" v-model="newPassword"
              class="mt-1 block w-full p-2 border border-gray-300 rounded" placeholder="Enter a new password"
              required />
          </div>
          <div>
            <label for="confirm-password" class="block text-sm font-medium text-gray-700">Confirm Password</label>
            <input type="password" id="confirm-password" v-model="confirmPassword"
              class="mt-1 block w-full p-2 border border-gray-300 rounded" placeholder="Confirm your new password"
              required />
          </div>
          <button type="submit" class="w-full button-settings text-white py-2 px-4 rounded hover:button-settings">
            Change Password
          </button>
        </form>
        <p v-if="successMessage" class="text-green-500 mt-4">{{ successMessage }}</p>
        <p v-if="errorMessage" class="text-red-500 mt-4">{{ errorMessage }}</p>
      </div>
    </main>
  </div>
</template>

<script>
import TopStrip, { TOP_STRIP_MODES } from "@/components/TopStrip.vue";
import settingsApi from '@/services/settings';
export default {
  data() {
    return {
      menuItems: [
        { name: 'changePassword', label: 'Change Password' },
      ],
      selectedMenu: 'changePassword',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      TOP_STRIP_MODES,
      successMessage: '',
      errorMessage: ''
    };
  },
  components: {
    TopStrip,
  },
  methods: {
    selectMenu(menuName) {
      this.selectedMenu = menuName;
    },
    goBack() {
      this.$router.push({ name: "SurveyDashboard" });
    },
    async changePassword() {
      if (this.newPassword !== this.confirmPassword) {
        alert('New passwords do not match!');
        return;
      }
      const userDataString = localStorage.getItem('userData')
      const userData = JSON.parse(userDataString);

      if (!userData || !userData.email) {
        alert('User data not found or invalid!');
        return;
      }

      console.log(userData)
      console.log(userData.email)
      const payload = {
        email: userData.email,
        current_password: this.currentPassword,
        password: this.newPassword
      }
      const response = await settingsApi.postData('admin/change_password', payload);
      console.log(response?.data, 'response.StatusCode')
      console.log(response?.data?.StatusCode, 'response.StatusCode')
      if (response?.data?.StatusCode === 200) {
        this.successMessage = 'Password changed successfully!';
        this.currentPassword = ''
        this.newPassword = ''
        this.confirmPassword = '' 

      } else {
        this.errorMessage = response?.Message;
      }
      console.log(response, 'response')
    },
  },
};
</script>

<style scoped>
body {
  font-family: 'Inter', sans-serif;
}

.button-settings {
  background-color: #8737e1;
}


.button-settings:hover {
  background-color: #9458d8; /* Tailwind's blue-600 */
}
</style>
<script>
import _ from "lodash";
import { useSnackbar } from "vue3-snackbar";
import surveyList from "@/services/partner/surveyList";
import downloadfile from "@/services/partner/downloadFile"
import fetchGeneralQuestions from "@/services/partner/fetchGeneralQuestions";
import Question from "@/components/Question.vue";
import postFileUpload from "@/services/partner/postFileUpload";
import initiateSurvey from "@/services/partner/initiateSurvey";
import TopStrip, { TOP_STRIP_MODES } from "@/components/TopStrip.vue";
import SendEmailIcon from "@/assets/img/send-email.svg"
import Download from "@/assets/img/file-download.svg"
import Loader from "@/components/Loader.vue";

const ACCEPTABLE_INPUT_DOC_FORMATS = ['.pdf', '.docx', '.txt']; // Provide acceptable extensions in lower case ONLY.

export default {
  name: "PartnerDashboard",
  components: { TopStrip, Question, Loader },
  data() {
    return {
      filter: {
        startDate: null,
        endDate: null,
      },
      surveyList: [],
      dataLoading: false,
      error: false,
      errorMessage: "",
      isInitiatingSurvey: false,
      generalQuestions: null,
      generalQuestionAnswers: [],
      initiatingSurveyStep: 0,
      uploadedFile: null,
      surveyDetails: {
        assignee: "",
        title: "",
        email: "",
        organisation: ""
      },
      uploadedFileSummary: "",
      ACCEPTABLE_INPUT_DOC_FORMATS,
      TOP_STRIP_MODES,
      SendEmailIcon,
      Download,
      snackbar: useSnackbar(),
      _: _
    }
  },
  methods: {
    async getSurveyList() {
      this.dataLoading = true;
      const { data, error } = await surveyList({ startDate: this.filter.startDate, endDate: this.filter.endDate });
      if (!error) {
        this.surveyList = data;
      } else {
        this.error = true;
        this.errorMessage = error.message;
        this.snackbar.add({
          type: 'error',
          text: this.errorMessage
        })
      }
      this.dataLoading = false;
    },
    async downloadfile(email) {
      this.dataLoading = true;
      console.log(email);
      const downloadRes = await downloadfile(email).catch(e=>console.error(e)).finally(()=>{
        this.dataLoading = false;
      })
      if (downloadRes.error) {
        console.log(downloadRes.error)
      }
    },
    async handleInitiateSurvey() {
      this.dataLoading = true;
      const generalQuestions = await fetchGeneralQuestions();
      if (!generalQuestions.error) {
        this.generalQuestions = _.flatten(_.map(generalQuestions.data, v => v.questions));
        this.isInitiatingSurvey = true;
        this.initiatingSurveyStep = 3;

        // Todo
        this.generalQuestionAnswers = [];
        this.uploadedFile = null;
        this.surveyDetails = {
          assignee: "",
          title: "",
          email: "",
          organisation: ""
        }
        this.uploadedFileSummary = "";
      } else {
        this.snackbar.add({
          type: 'error',
          text: 'An Error Occurred.'
        })
      }
      this.dataLoading = false;
    },
    handleAnswer(questionIndex, selection, optionNumber, otherOptions) {
      this.generalQuestionAnswers[questionIndex] = {
        selection,
        optionNumber
      }
    },
    handleGeneralQuestionNext() {
      this.initiatingSurveyStep = 2;
    },
    onFileUploadChange() {
      this.uploadedFile = [...this.$refs.file.files];

      let selectedFile = null;
      try {
        const extensionMatchPattern = /.*(\.[\w]*)/;
        const extension = extensionMatchPattern.exec(this.uploadedFile[0].name)[1];
        if (ACCEPTABLE_INPUT_DOC_FORMATS.includes(extension.toLowerCase())) {
          selectedFile = this.uploadedFile[0]
        }
      } catch (e) {
      }

      this.uploadedFile = selectedFile;
    },
    onFileUploadRemove() {
      this.uploadedFile = null;
      this.$refs.file.value = "";
    },
    async fileUpload() {
      if (this.uploadedFile && !_.isEmpty(this.uploadedFile.name)) {
        this.dataLoading = true;
        const resp = await postFileUpload({
          file: this.uploadedFile
        });
        if (!resp.error) {
          this.uploadedFileSummary = resp.data;
        } else {
          this.snackbar.add({
            type: 'error',
            text: 'An Error Occurred while uploading file'
          })
        }
        this.dataLoading = false;
      }
    },
    handleFileUploadNext() {
      this.initiatingSurveyStep = 3;
    },
    async handleSubmitInitiateDetails() {
      await this.fileUpload();
      if (this.surveyDetails
        && !_.isEmpty(this.surveyDetails.assignee)
        && !_.isEmpty(this.surveyDetails.email)
        && !_.isEmpty(this.surveyDetails.organisation)
      ) {
        this.dataLoading = true;
        const resp = await initiateSurvey({
          name: this.surveyDetails.assignee,
          contact_number: "",
          email: this.surveyDetails.email,
          company: this.surveyDetails.organisation,
          general_segment_answer: JSON.stringify(this.generalQuestionAnswers),
          file_summary: this.uploadedFileSummary
        });

        if (!resp.error) {
          await this.getSurveyList();
          this.isInitiatingSurvey = false;
        } else {
          this.snackbar.add({
            type: 'error',
            text: 'An Error Occurred.'
          })
        }
        this.dataLoading = false;
      }
    },

    handleStepOneBack() {
      this.isInitiatingSurvey = false;
      this.initiatingSurveyStep = 0;
    },
    handleStepTwoBack() {
      tthis.isInitiatingSurvey = false;
      this.initiatingSurveyStep = 0;
    },
    handleStepThreeBack() {
      this.isInitiatingSurvey = false;
      this.initiatingSurveyStep = 0;
    }
  },
  async created() {
    await this.getSurveyList();
  }
}
</script>
<template>
  <div>
    <div class="top-strip">
      <TopStrip :mode="TOP_STRIP_MODES.PAGE" />
    </div>
    <!--    <div class="flex w-full justify-between" style="box-shadow: 0 1px 1px 0 #666666">-->
    <!--      <div class="flex gap-3">-->
    <!--        <div>Filter</div>-->
    <!--        <div>Reset</div>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <span>Selected Period</span>-->
    <!--        <span>12/12/2021 - 12/12/2022</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <Loader :loading="dataLoading" :add-margin-for-left-nav="false" />
    <div class="flex flex-col gap-1 mt-3 pl-3" v-if="!isInitiatingSurvey">
      <div class="flex justify-between p-3">
        <div style="font-weight: 600; font-size: 31px;">Dashboard</div>
        <div>
          <button
            style="border-radius: 6px; padding: 8px 16px; background: #8737E1; color: #FFFFFF; font-weight: 600; line-height: 24px; margin-right: 20px"
            @click="handleInitiateSurvey">
            Initiate Survey
          </button>
          <button
            style="border-radius: 6px; padding: 8px 16px; background: #8737E1; color: #FFFFFF; font-weight: 600; line-height: 24px; margin-right: 20px"
            @click="downloadfile('')">
            Download All Data
          </button>
        </div>
      </div>
      <div class="p-6 flex flex-col gap-3 responsive-container">
        <div v-for="(survey, surveyIndex) in surveyList" :key="survey.id" class="survey-row">
          <div class="flex-item">
            <div class="title">{{ survey.assignee }}</div>
            <div class="subtitle">{{ survey.title }}</div>
          </div>
          <div class="flex-item">
            <div class="title">{{ survey.organisation }}</div>
            <div class="subtitle">{{ survey.email }}            
            </div>
          </div>
          <div class="flex-item">
            <div class="title">Date</div>
            <div class="subtitle">{{ survey.invitedDate }}</div>
          </div>
          <div class="flex-item status-container">
            <div class="status">{{ _.capitalize(survey.currentStatus) }}</div>
          </div>
          <div class="flex-item status-container">
            <div class="status_perc w-full flex items-center space-x-2">
              <div class="w-full bg-gray-200 rounded-full h-4">
                <div 
                  class="bg-blue-500 h-4 rounded-full" 
                  :style="{ width: survey.status_perc + '%' }">
                </div>
              </div>
              <small>{{ survey.status_perc?.toFixed(2) }}%</small>
            </div>
          </div>
          <div class="flex-item send-email">
            <div class="flex align-middle gap-1">
              <img :src="SendEmailIcon" alt="" />
              <div class="send-email-text">Send Email</div>
            </div>
          </div>
          <div class="flex-item send-email">
            <div v-if="survey.currentStatus === 'Ongoing'" class="flex align-middle gap-1" @click="downloadfile(survey.email)">
              <img class="download" :src="Download" alt="" />
              <div class="send-email-text">Download</div>
            </div>
          </div>
          
          
        </div>

        <div v-if="_.isEmpty(surveyList) && !dataLoading" class="no-records">
          No records
        </div>
      </div>

    </div>
    <div v-if="generalQuestions && initiatingSurveyStep === 1">
      <div class="flex flex-col m-16">
        <div>

        </div>
        <div style="font-weight: 600; font-size: 31px; margin-top: 30px">General</div>
        <div v-for="(question, questionIndex) in generalQuestions" :key="questionIndex">
          <div :class="questionIndex === 0 ? 'level1Question' : 'level2Question'">
            <Question :questionText="question?.title" :options="question?.options" :questionType="question?.type"
              :handleAnswer="(selection, optionNumber, otherOptions = {}) => handleAnswer(questionIndex, selection, optionNumber, otherOptions)"
              :isMainQuestion="false" :isAiGenerated="question?.isAiGenerated" :currentQuestion="true"
              :answer="question?.answer" :revisitingSegment="false" :readOnly="false" top-margin="10px" />
          </div>

        </div>
        <div class="self-end">
        </div>
      </div>
    </div>

    <div v-if="isInitiatingSurvey && initiatingSurveyStep === 2">

    </div>
    <div v-if="isInitiatingSurvey && initiatingSurveyStep === 3" style="width: 100%;">
      <div class="flex flex-col m-16 gap-6">
        <div>
          <button
            style="width: 78px; height: 36px; border-radius: 8px; background: #8737E1; color: #FFFFFF; font-weight: 700"
            @click="handleStepThreeBack">Back</button>
        </div>
        <div style="font-weight: 600; font-size: 31px; margin-top: 30px">Attach the latest available annual report
          (where available)</div>

        <div class="flex">
          <input type="text" disabled :value="uploadedFile?.name" class="input-file-name" />
          <input id="assetsFieldHandle" ref="file" :accept="`${ACCEPTABLE_INPUT_DOC_FORMATS.join(',')}`"
            class="w-px h-px opacity-0 overflow-hidden absolute" name="assets_field_handle" type="file"
            @change="onFileUploadChange">

          <label v-if="_.isEmpty(uploadedFile?.name)" class="input-button w-28 border mt-2 px-2"
            style="width: 78px; height: 36px; border-radius: 8px; background: #8737E1; color: #FFFFFF; font-weight: 700"
            for="assetsFieldHandle">
            Browse
          </label>
          <label @click="onFileUploadRemove" v-if="!_.isEmpty(uploadedFile?.name)"
            class="input-button w-28 border mt-2 px-2"
            style="width: 78px; height: 36px; border-radius: 8px; background: #8737E1; color: #FFFFFF; font-weight: 700">
            Remove
          </label>
        </div>

        <div class="self-end">

        </div>
      </div>
      <div class="flex flex-col m-16 gap-6">
        <div>

        </div>
        <div class="flex flex-col gap-3">
          <div style="font-size: 34px; font-weight: 600;">Details</div>
          <div class="flex flex-col gap-1">
            <div style="font-size: 16px; font-weight: 600">Company Name</div>
            <div><input type="text" style="border: 1px solid #D7D7D7" class="input-file-name"
                v-model="surveyDetails.organisation" /></div>
          </div>
          <div class="flex flex-col gap-1">
            <div style="font-size: 16px; font-weight: 600">Contact Name</div>
            <div><input type="text" style="border: 1px solid #D7D7D7" class="input-file-name"
                v-model="surveyDetails.assignee" /></div>
          </div>
          <div class="flex flex-col gap-1">
            <div style="font-size: 16px; font-weight: 600">Email</div>
            <div><input type="email" style="border: 1px solid #D7D7D7" class="input-file-name"
                v-model="surveyDetails.email" /></div>
          </div>
          <div class="flex flex-col gap-1">
            <div style="font-size: 16px; font-weight: 600">Designation</div>
            <div><input type="text" style="border: 1px solid #D7D7D7" class="input-file-name"
                v-model="surveyDetails.title" /></div>
          </div>
          <div>
            <button
              style="border-radius: 6px; padding: 8px 16px; background: #8737E1; color: #FFFFFF; font-weight: 600; line-height: 24px; margin-top: 20px"
              @click="handleSubmitInitiateDetails">
              Generate Link & Send to Client
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.download {
  width: 4vh !important;
  height: 3.5vh !important;
}

.input-file-name {
  border: 1px solid #8737E1;
  height: 36px;
  width: 560px;
  padding-left: 10px
}

.input-button {
  height: 36px !important;
  line-height: 36px;
  margin-top: 0;
  margin-left: 10px;
}

.responsive-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.responsive-card {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 16px;
  border: 1px solid #ebe8ff;
  border-radius: 15px;
  gap: 12px;
  align-items: center;
}

.responsive-index {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background: #8737e1;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
  font-weight: bold;
  font-size: 18px;
}

.title {
  font-size: 18px;
  font-weight: 700;
}

.subtitle {
  font-weight: 300;
  font-size: 16px;
  color: #666;
}

.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  width: 96px;
  height: 26px;
  line-height: 26px;
  background: #aeda50;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}

.send-email {
  justify-self: end;
  margin-right: 16px;
}

.send-email-text {
  color: #8737e1;
  font-weight: 700;
}

.no-records {
  border: 1px solid #8737e1;
  line-height: 56px;
  height: 56px;
  background: #efefef;
  text-align: center;
}

.survey-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.flex-item {
  flex: 1;
  text-align: left;
  padding: 0 10px;
}

.flex-item.send-email {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-container {
  text-align: center;
}

.title {
  font-size: 14px;
  font-weight: bold;
}

.subtitle {
  font-size: 12px;
  color: #666;
}

.download {
  width: 16px;
  height: 16px;
}

.send-email-text {
  font-size: 12px;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .responsive-card {
    grid-template-columns: 1fr;
    gap: 16px;
    text-align: left;
  }

  .responsive-index {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}
</style>
<script>
import NextArrowIcon from "@/assets/img/next-arrow.svg";

export default {
  name: "FreeText",
  props: {
    handleChange: {
      type: Function,
      required: true
    },
    valType: {
      type: String,
      required: true
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    },
    unit: {
      type: String,
      required: false,
      default: ""
    },
  },
  data(){
    return {
      value: this.previousAnswer ? this.previousAnswer.selection : "",
      showSubmitButton: false,
      NextArrowIcon,
    }
  },
  methods: {
    handleEdit(){
      this.showSubmitButton = true;
    }
  },
  watch: {
    currentQuestion(newValue){
      this.showSubmitButton = !!newValue;
    }
  }
}
</script>

<template>
  <div class="que-free-text">
    <div class="que-free-text-input">
      <fieldset class="w-full space-y-1 dark:text-gray-800">
        <div class="flex w-[30%]">
          <input
            :type="valType"
            v-model="value"
            class="que-free-text-area pl-3 pr-12 py-2 h-12 flex flex-1 border text-md rounded-l-md"
            :disabled="readOnly"
            placeholder="Your answer typed here.."
            v-on:keyup="handleEdit"
          >
          <span class="flex items-center px-5 pointer-events-none sm:text-sm rounded-r-md unit" v-if="unit">{{ unit }}</span>
        </div>

        <button 
          class="flex justify-center items-center mt-4 app-submit-button" 
          v-if="showSubmitButton && !readOnly" 
          @click="() => handleChange(this.value, 0)"
        >
          <img style="margin: 0 auto" :src="NextArrowIcon" alt="">
        </button>
      </fieldset>
    </div>
  </div>
</template>

<style scoped>
.que-free-text{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.que-free-text-input{
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.que-free-text-area {
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  text-overflow: ellipsis;
  padding-left: 10px;
  outline: none;
  resize: none;
}

.que-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  padding: 10px;
  background: #9B9B9B;
  height: 51px;
}
.unit {
  background-color: #8737E1;
  color: #fff;
}
</style>
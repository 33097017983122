<script>
import _ from "lodash";
import DropDownQuestion from "@/components/Question/DropDownQuestion.vue";
import CheckboxGroup from "@/components/Question/BoxedMultiSelect.vue";
import MultiSelect from "@/components/Question/MultiSelect.vue";
import DragRank from "@/components/Question/DragRankNew.vue";
import UpDown from "@/components/Question/UpDown.vue";
import FreeTextPerOption from "@/components/Question/FreeTextPerOption.vue";
import FreeText from "@/components/Question/FreeText.vue";
import MFTPO from "@/components/Question/MFTPO.vue";
import RBPO from "@/components/Question/RBPO.vue";
import Rate from "@/components/Question/Rate.vue";
import SingleSelectWithDetails from "@/components/Question/SingleSelectWithDetails.vue";
import MultiSelectWithDetails from "@/components/Question/MultiSelectWithDetails.vue";

const QUESTION_TYPES = {
  SELECT:"SELECT",
  CHECKBOX: "CHECKBOX",
  FREETEXT: "FREETEXT",
  MULTI_SELECT: "MULTI_SELECT",
  UP_DOWN: "UP_DOWN",
  DRAG_RANK: "DRAG_RANK",
  FREE_TEXT_PER_OPTION: "FREE_TEXT_PER_OPTION",
  MULTIPLE_INPUT_PER_OPTION: "MULTIPLE_INPUT_PER_OPTION",
  RADIO_PER_OPTION: "RADIO_PER_OPTION",
  RATE_QUESTION: "RATE_QUESTION",
  SINGLE_SELECT_WITH_DETAILS: "SINGLE_SELECT_WITH_DETAILS",
  MULTI_SELECT_WITH_DETAILS: "MULTI_SELECT_WITH_DETAILS"
}

const BE_QUESTION_TYPES = {
  FT: "FT",
  FQ: "FQ",
  MS: "MS",
  SS: "SS",
  UD: "UD",
  RR: "RR",
  FTPO: "FTPO",
  MFTPO: "MFTPO",
  MFQPO: "MFQPO",
  RBPO: "RBO",
  RATE: "Rate",
  SSFQ: "SSFQ",
  SSFT: "SSFT",
  MSFQ: "MSFQ",
  MSFT: "MSFT"
}

const SELF_OPTION_LABEL = "Other (please specify)";

const UNITS_SEPARATOR = "___";

export default {
  components: {CheckboxGroup, DropDownQuestion, MultiSelect, DragRank, UpDown, FreeTextPerOption, FreeText, MFTPO, RBPO, Rate, SingleSelectWithDetails, MultiSelectWithDetails},
  props: {
    questionText: {
      type: String,
      required: true
    },
    questionType: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    handleAnswer: {
      type: Function,
      required: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    answer: {
      type: Object,
      required: false,
      default: null
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    },
    topMargin: {
      type: String,
      required: false,
      default: "80px"
    },
    unit: {
      type: String,
      required: false,
      default: ""
    },
  },

  data(){
    return {
      QUESTION_TYPES,
      BE_QUESTION_TYPES,
      currentQuestionVariables: {},
      _: _
    }
  },

  computed: {
    uiQuestionType(){
      if (this.questionType === BE_QUESTION_TYPES.FT){
        this.currentQuestionVariables = {
          type: "text"
        }
        return QUESTION_TYPES.FREETEXT
      }

      if (this.questionType === BE_QUESTION_TYPES.FQ){
        this.currentQuestionVariables = {
          type: "number"
        }
        return QUESTION_TYPES.FREETEXT
      }

      if (this.questionType === BE_QUESTION_TYPES.SS){
        if (_.size(this.options) > 4){
          return QUESTION_TYPES.CHECKBOX
        }

        if (_.size(this.options) > 1 && _.size(this.options) <= 4){
          if (_.indexOf(this.options, SELF_OPTION_LABEL) !== -1){
            return QUESTION_TYPES.CHECKBOX
          }
          return QUESTION_TYPES.CHECKBOX
        }
      }

      if (this.questionType === BE_QUESTION_TYPES.MS){
        return QUESTION_TYPES.CHECKBOX
      }

      if (_.startsWith(this.questionType, 'MS___')){
        this.currentQuestionVariables = {
          maxSelection: _.toNumber(_.split(this.questionType, '___')[1])
        }
        return QUESTION_TYPES.CHECKBOX
      }

      if (this.questionType === BE_QUESTION_TYPES.RR){
        return QUESTION_TYPES.DRAG_RANK
      }

      if (this.questionType === BE_QUESTION_TYPES.RATE){
        return QUESTION_TYPES.RATE_QUESTION
      }

      if (this.questionType === BE_QUESTION_TYPES.SSFQ){
        this.currentQuestionVariables = {
          mode: {
            maxSelection: "1",
            freeType: "number"
          }
        }
        return QUESTION_TYPES.SINGLE_SELECT_WITH_DETAILS
      }
      if (this.questionType === BE_QUESTION_TYPES.SSFT){
        this.currentQuestionVariables = {
          mode: {
            maxSelection: "1",
            freeType: "text"
          }
        }
        return QUESTION_TYPES.SINGLE_SELECT_WITH_DETAILS
      }
      if (this.questionType === BE_QUESTION_TYPES.MSFQ){
        this.currentQuestionVariables = {
          mode: {
            maxSelection: "n",
            freeType: "number"
          }
        }
        return QUESTION_TYPES.MULTI_SELECT_WITH_DETAILS
      }

      if (this.questionType === BE_QUESTION_TYPES.MSFT){
        this.currentQuestionVariables = {
          mode: {
            maxSelection: "n",
            freeType: "text"
          }
        }
        return QUESTION_TYPES.MULTI_SELECT_WITH_DETAILS
      }

      if (this.questionType === BE_QUESTION_TYPES.FTPO){
        return QUESTION_TYPES.FREE_TEXT_PER_OPTION
      }
      if (_.startsWith(this.questionType, BE_QUESTION_TYPES.MFTPO)){
        const units = _.split(this.questionType, UNITS_SEPARATOR);
        const numberOfCols = units[1];
        let cols = units[2];
        cols = _.slice(_.split(cols, ","), 0, numberOfCols);
        this.currentQuestionVariables.answerCols = _.map(cols, v => {
          return {
            label: v,
            type: "text"
          }
        })
        return QUESTION_TYPES.MULTIPLE_INPUT_PER_OPTION
      }

      if (_.startsWith(this.questionType, BE_QUESTION_TYPES.MFQPO)){
        const units = _.split(this.questionType, UNITS_SEPARATOR);
        const numberOfCols = units[1];
        let cols = units[2];
        cols = _.slice(_.split(cols, ","), 0, numberOfCols);
        this.currentQuestionVariables.answerCols = _.map(cols, v => {
          return {
            label: v,
            type: "number"
          }
        })
        return QUESTION_TYPES.MULTIPLE_INPUT_PER_OPTION
      }

      if (_.startsWith(this.questionType, BE_QUESTION_TYPES.RBPO)){
        const units = _.split(this.questionType, UNITS_SEPARATOR);
        const numberOfCols = units[1];
        let cols = units[2];
        cols = _.slice(_.split(cols, ","), 0, numberOfCols);
        this.currentQuestionVariables.answerCols = _.map(cols, v => {
          return {
            label: v,
          }
        })
        return QUESTION_TYPES.RADIO_PER_OPTION
      }

      if (_.startsWith(this.questionType, BE_QUESTION_TYPES.UD)){
        return QUESTION_TYPES.UP_DOWN
      }
    },

    upDownKeyWords(){
      if (_.startsWith(this.questionType, BE_QUESTION_TYPES.UD)){
        const keyWordsStr = this.questionType.substring(BE_QUESTION_TYPES.UD.length);
        return _.map(_.split(keyWordsStr, ","), v => _.trim(v))
      }
    }
  },

  mounted() {
    if (!this.revisitingSegment){
      this.$nextTick().then(() => {
        this.$refs.quequestion.scrollIntoView({ behavior: "smooth", inline: "center" })
      })
    }
  },

  // created() {
  //   if (this.uiQuestionType === QUESTION_TYPES.MULTI_SELECT){
  //     setTimeout(()=>{
  //       this.$forceUpdate();
  //     }, 3000)
  //   }
  // }
}
</script>

<template>
  <div ref="quequestion" class="que-container" :style="`margin-top: ${topMargin};`">
    <label :class="`${isMainQuestion ? 'que-questiontext-primary' : 'que-questiontext-secondary'}`" >{{questionText ?? ""}} <span class="que-ai-generated-text" v-if="isAiGenerated">[AI Generated]</span></label>

    <DropDownQuestion
        v-if="uiQuestionType === QUESTION_TYPES.SELECT"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
    />
    <CheckboxGroup
        v-if="uiQuestionType === QUESTION_TYPES.CHECKBOX"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
        :is-multi-select="this.questionType === BE_QUESTION_TYPES.MS || _.startsWith(this.questionType, 'MS___')"
        :max-selection="_.get(this.currentQuestionVariables, 'maxSelection', 0)"
    />

    <DragRank
        v-if="uiQuestionType === QUESTION_TYPES.DRAG_RANK"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
    />

    <MultiSelect
        v-if="uiQuestionType === QUESTION_TYPES.MULTI_SELECT"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
    />

    <UpDown
        v-if="uiQuestionType === QUESTION_TYPES.UP_DOWN"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :keyWords="upDownKeyWords"
        :readOnly="readOnly"
    />

    <FreeTextPerOption
        v-if="uiQuestionType === QUESTION_TYPES.FREE_TEXT_PER_OPTION"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
    />

    <MFTPO
        v-if="uiQuestionType === QUESTION_TYPES.MULTIPLE_INPUT_PER_OPTION"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :answer-cols="this.currentQuestionVariables.answerCols"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
        :questionType="questionType"
    />

    <RBPO
        v-if="uiQuestionType === QUESTION_TYPES.RADIO_PER_OPTION"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :answer-cols="this.currentQuestionVariables.answerCols"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
    />

    <Rate
        v-if="uiQuestionType === QUESTION_TYPES.RATE_QUESTION"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
    />

    <SingleSelectWithDetails
        v-if="uiQuestionType === QUESTION_TYPES.SINGLE_SELECT_WITH_DETAILS"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :mode="this.currentQuestionVariables.mode"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
    />

    <MultiSelectWithDetails
        v-if="uiQuestionType === QUESTION_TYPES.MULTI_SELECT_WITH_DETAILS"
        :currentQuestion="currentQuestion"
        :isMainQuestion="isMainQuestion"
        :title="`${questionText}`"
        :options="options"
        :mode="this.currentQuestionVariables.mode"
        :isAiGenerated="isAiGenerated"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
    />

    <FreeText
        v-if="uiQuestionType === QUESTION_TYPES.FREETEXT"
        :currentQuestion="currentQuestion"
        :val-type="this.currentQuestionVariables.type"
        :previousAnswer="answer"
        :revisitingSegment="revisitingSegment"
        :handleChange="handleAnswer"
        :readOnly="readOnly"
        :unit="unit"
    />
  </div>
</template>

<style scoped>
.que-questiontext-primary{
  color: #7823DC;
  font-weight: 700;
}

.que-questiontext-secondary{
  color: #333333;
  font-weight: 700;
}

.que-ai-generated-text {
  font-weight: 700;
  color: #7823DC;
}



.que-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.que-ai-generated-text {
  font-weight: 800;
  color: #7823DC;
}
</style>
<template>
  <div v-if="showSuccess" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
    <div class="bg-white rounded-lg shadow-lg p-6">
      <button class="absolute top-3 right-3 text-gray-400 hover:text-gray-800" @click="closeSuccess">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6 text-green-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <div class="ml-4 text-lg font-medium text-gray-800">Invite Sent Successfully!</div>
      </div>
      <p class="mt-2 text-sm text-gray-600">Invite has been sent successfully to <b>{{ inviteeName }}</b>.</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  showSuccess: {
    type: Boolean,
    required: true
  },
  inviteeName: {
    type: String,
    required: true,
    default: 'Guest'
  }
});

function closeSuccess() {
}
</script>

<template>
  <div class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    <div class="bg-white w-[40%] rounded-md shadow-lg z-[99999]">
      <div class="flex justify-between items-center px-4 py-3">
        <h2 class="text-[1.125rem] font-semibold text-center w-full">Share</h2>
        <button @click="closeDelegationPopup" class="text-gray-500 hover:text-gray-700">
          &times;
        </button>
      </div>
      <div class="py-4 px-28">
        <div class="mb-4">
          <label class="block text-[1.125rem] font-medium text-gray-700 text-left" for="name">Name</label>
          <input
            v-model="localFormData.name"
            required
            id="name"
            type="text"
            class="w-full px-3 py-1 mt-1 border rounded focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            placeholder="Enter your name"
          />
          <p v-if="errors.name" class="text-red-500 text-sm">{{ errors.name }}</p>
        </div>
        <div class="mb-4">
          <label class="block text-[1.125rem] font-medium text-gray-700 text-left" for="email">Email Id</label>
          <input
            v-model="localFormData.email"
            required
            id="email"
            type="email"
            class="w-full px-3 py-1 mt-1 border rounded focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            placeholder="Enter your email"
          />
          <p v-if="errors.email" class="text-red-500 text-sm">{{ errors.email }}</p>
        </div>
        <div class="mb-4">
          <label class="block text-[1.125rem] font-medium text-gray-700 text-left">History/Show all Pillars</label>
          <div class="flex items-center mt-2">
            <label class="flex items-center mr-4">
              <input
                v-model="localFormData.history"
                type="radio"
                value="1"
                class="text-purple-500 focus:ring-purple-500"
              />
              <span class="ml-2 text-[1rem]">ON</span>
            </label>
            <label class="flex items-center ml-16">
              <input
                v-model="localFormData.history"
                type="radio"
                value="0"
                class="text-purple-500 focus:ring-purple-500"
              />
              <span class="ml-2 text-[1rem]">OFF</span>
            </label>
          </div>
          <p v-if="errors.history" class="text-red-500 text-sm">{{ errors.history }}</p>
        </div>
        <div class="flex justify-center">
          <button
            @click="submitPopup"
            class="px-4 py-2 text-white bg-purple-500 rounded hover:bg-purple-600 text-sm"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pillarApi from '@/services/pillarApi';
import { useSnackbar } from "vue3-snackbar";
export default {
  props: {
    shareFormData: {
      type: Object,
      required: true,
    },
    closeHandler: {
      type: Function,
      required: true,
    },
    submitHandler: {
      type: Function,
      required: true,
    },
    invite: {
      type: String,
      required: true,
    },
    userData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localFormData: { ...this.shareFormData },
      errors: {
        name: '',
        email: '',
        history: ''
      },
      snackbar: useSnackbar()
    };
  },
  methods: {
    closeDelegationPopup() {
      this.closeHandler();
    },
    validateForm() {
      this.errors = { name: '', email: '', history: '' };

      if (!this.localFormData.name) {
        this.errors.name = 'Name is required';
      }

      if (!this.localFormData.email) {
        this.errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(this.localFormData.email)) {
        this.errors.email = 'Email is invalid';
      }

      if (!this.localFormData.history) {
        this.errors.history = 'Please select a history option';
      }

      return Object.values(this.errors).every((error) => error === '');
    },
    async submitPopup() {
      if (this.validateForm()) {
        try {
          this.localFormData.companyName = this.userData.company;
          this.localFormData.invitedBy = this.userData.invitedBy;
          this.localFormData.typeOfUser = 'invited_on_request';
          this.localFormData.history = Number(this.localFormData.history)
          if (this.localFormData.history === 0) {
            this.localFormData.pillar_id = this.userData.pillarId;
          }
          const response = await pillarApi.postData('admin/create_user', this.localFormData);
          this.submitHandler(this.localFormData);
          this.closeDelegationPopup();
          this.snackbar.add({
            type: 'success',
            text: 'User Added!'
          });
        } catch (error) {
          this.snackbar.add({
            type: 'error',
            text: 'User Added already!'
          });
        }
      }
    },
  }
};
</script>

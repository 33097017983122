import _ from 'lodash';
import adminApi from '@/services/superAdmin/adminApi';

const devResponse = {
    data: {
        Result:  [{
            assignee: "Mohan Kumar",
            title: "Director",
            email: "mohan.kumar@accenture.com",
            invitedDate: "2024-11-13T14:30:43Z",
            currentStatus: "filled",
            surveyId: "1",
            organisation: "Accenture"
        }, {
            assignee: "Rakesh Sharma",
            title: "Principal",
            email: "rakesh.sharma@honeywell.com",
            invitedDate: "2024-11-13T14:30:43Z",
            currentStatus: "invited",
            surveyId: "2",
            organisation: "Honeywell"
        }, {
            assignee: "Sanjay Malhotra",
            title: "Partner",
            email: "sanjay.malhotra1@olam.com",
            invitedDate: "2024-11-13T14:30:43Z",
            currentStatus: "invited",
            surveyId: "3",
            organisation: "Olam"
        }]
    }
}

const formatResponseForUI = (resp) => {
    if (resp && resp.error){
        return resp;
    }
    const respResult = resp?.data?.Result?.results || {};
    return {
        data: _.map(respResult, v => {
            return {
                assignee: `${v.first_name} ${v.last_name}`,
                email: v.email,
                title: "Director",
                invitedDate: `${v.invited_on}`,
                currentStatus: `${v.accept_status}`,
                organisation: `${v.company}`,
                status_perc: v.status_perc
            }
        }),
        error: null
    }
}

export default async (
    {
        startDate,
        endDate
    })=>{
    const response = await adminApi.getData(`invite_extuser`).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return formatResponseForUI(response);
}
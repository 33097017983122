<script>
import _ from "lodash";
import Logo from "@/assets/img/kearney-logo-black.svg";
import CiruclarProgress from "@/components/CiruclarProgress.vue";
import GeneralPillarIconActive from "@/assets/img/pillarIcons/general_pillar_icon-active.svg";
import GeneralPillarIconInActive from "@/assets/img/pillarIcons/general_pillar_icon-inactive.svg";
import CapitalStrategyPillarIconActive from "@/assets/img/pillarIcons/capital_strategy_pillar_icon-active.svg";
import CapitalStrategyPillarIconInActive from "@/assets/img/pillarIcons/capital_strategy_pillar_icon-inactive.svg";
import PortfoliManagementPillarIconActive from "@/assets/img/pillarIcons/portfolio_management_pillar_icon-active.svg";
import PortfoliManagementPillarIconInActive from "@/assets/img/pillarIcons/portfolio_management_pillar_icon-inactive.svg";
import FinancingActive from "@/assets/img/pillarIcons/financing-active.svg";
import FinancingInActive from "@/assets/img/pillarIcons/financing-inactive.svg";
import ProcurementActive from "@/assets/img/pillarIcons/procurement-active.svg";
import ProcurementInActive from "@/assets/img/pillarIcons/procurement-inactive.svg";
import ConstructionActive from "@/assets/img/pillarIcons/construction-active.svg";
import ConstructionInActive from "@/assets/img/pillarIcons/construction-inactive.svg";
import OPMActive from "@/assets/img/pillarIcons/opm-active.svg";
import OPMInActive from "@/assets/img/pillarIcons/opm-inactive.svg";
import OCMKActive from "@/assets/img/pillarIcons/ocmk-active.svg";
import OCMKInActive from "@/assets/img/pillarIcons/ocmk-inactive.svg";
import MRPRActive from "@/assets/img/pillarIcons/opm-active.svg";
import MRPRInActive from "@/assets/img/pillarIcons/opm-inactive.svg";
import BgImage from "@/assets/img/bg.png";
import NavToggleIcon from "@/assets/img/nav-toggle-icon.svg";

const PILLAR_ICON_MAPPING = {
  "General": {
    active: GeneralPillarIconActive,
    inActive: GeneralPillarIconInActive
  },
  "Capital Strategy & Planning": {
    active: CapitalStrategyPillarIconActive,
    inActive: CapitalStrategyPillarIconInActive
  },
  "Design & Engineering": {
    active: PortfoliManagementPillarIconActive,
    inActive: PortfoliManagementPillarIconInActive
  },
  "Portfolio Management & Stage Gate": {
    active: GeneralPillarIconActive,
    inActive: GeneralPillarIconInActive
  },
  "Financing": {
    active: FinancingActive,
    inActive: FinancingInActive
  },
  "Procurement & Supplier Management": {
    active: ProcurementActive,
    inActive: ProcurementInActive
  },
  "Construction & Commissioning": {
    active: ConstructionActive,
    inActive: ConstructionInActive
  },
  "Operations & Maintenance": {
    active: OPMActive,
    inActive: OPMInActive
  },
  "Organization & Operating Model": {
    active: OCMKActive,
    inActive: OCMKInActive
  },
  "Reporting, Performance & Risks": {
    active: MRPRActive,
    inActive: MRPRInActive
  },
  "IT, Digital & Technologies": {
    active: GeneralPillarIconActive,
    inActive: GeneralPillarIconInActive
  },
  "ESG": {
    active: GeneralPillarIconActive,
    inActive: GeneralPillarIconInActive
  }
};

export default {
  name: "NavBar",
  components: {
    CiruclarProgress
  },
  props: {
    menuOptions: {
      type: Object,
      required: true
    },
    onNavItemSelection: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      Logo,
      PILLAR_ICON_MAPPING,
      GeneralPillarIconActive,
      GeneralPillarIconInActive,
      BgImage,
      NavToggleIcon,
      _: _,
      isSidebarVisible: true,
    }
  },
  methods: {
    onMenuItemClick(newIndex) {
      this.onNavItemSelection(newIndex)
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
  },
  computed: {
    navItemSelectedIndex() {
      return this.menuOptions?.selected;
    }
  },

  watch: {
    navItemSelectedIndex(newIndex) {
      this.onNavItemSelection(newIndex)
    }
  }
}
</script>

<template>

  <div class="logo-image always-visible">
    <img :src="Logo" alt="logo">
  </div>
  <span v-if="!isSidebarVisible" class="material-symbols-outlined menu-button" @click="toggleSidebar">
    menu
  </span>

  <div v-show="isSidebarVisible" class="sidebar">
    <div class="flex flex-col h-full justify-between">
      <div class="flex flex-col">

        <div class="survey-info-container" :style="`background-image: url(${BgImage});`">
          <div class="nav-toggle-icon">
            <img :src="NavToggleIcon" alt="" />
          </div>
          <span class="material-symbols-outlined survey-info-close" @click="toggleSidebar">
            close
          </span>
          <div class="survey-info">
            <div class="survey-info-title">ExCap Survey</div>
            <div class="survey-info-subtitle">Estimated Duration: 45mins</div>
          </div>
        </div>
        <div class="navigation-items ">
          <div v-for="(item, index) in menuOptions?.items"
            :class="`navigation-item-primary ${navItemSelectedIndex === index ? 'navigation-item-primary-selected' : ''}`"
            :style="`${item.completion > 0 && navItemSelectedIndex !== index ? 'color: #333333' : ''}`"
            @click="() => onMenuItemClick(index)" :key="item.title">
            <div class="pillar-icon-title">
              <img :src="_.get(_.get(PILLAR_ICON_MAPPING,
                `${_.trim(item.title)}`, PILLAR_ICON_MAPPING['General']),
                `${navItemSelectedIndex === index ? 'active' : 'inActive'}`)" alt="" />
              {{ item.title }}
            </div>
            <div class="navigation-item-progress">
              <CiruclarProgress :value="item.completion" />
            </div>
          </div>
        </div>
      </div>
      <div class="navigation-bottom-section">
        <div class="navigation-b-sub">Powered By </div>
        <div class="navigation-b-main">KEARNEY</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.navigation-item-progress {}

.survey-info-close {
  
  position: fixed;
  left: 16rem;

  color: white;
}

.sidebar {
  position: fixed;
  left: 0;
  height: 100%;
  width: 289px;
  background: #ffffff;
  overflow-y: auto;
  box-shadow: 4px 4px 4px 0 #8737E11A;
  transition: transform 0.3s ease-in-out;
}

.logo-image {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  height: 68px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 289px;
  background-color: #fff;
  box-shadow: 0px 4px 2px -2px gray;
}

.always-visible {
  z-index: 101;
}

.logo-image>img {
  margin: 20px auto;
}

.navigation-items {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 46px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.menu-icon {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #8737e1;
  z-index: 1000;
}

.navigation-item-primary {
  text-align: left;
  font-size: 14px;
  padding: 10px 10px 10px 20px;
  margin: 0 10px 10px 10px;
  border-radius: 10px;
  color: #AAB5B7;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-item-primary-selected {
  background-color: #E5CFFF;
  color: #8737E1;
}

.pillar-icon-title {
  display: flex;
  gap: 5px;
}

.menu-button {
  position: fixed;
  top: 15px;
  left: 15px;
  background: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
  z-index: 102;
}

.navigation-bottom-section {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.navigation-b-sub {
  font-size: 10px;
  font-weight: 150;
}

.navigation-b-main {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
}

.navigation-logout-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}

.navigation-logout-button {
  display: flex;
  justify-content: flex-start;
  color: #f4f4f6;
  gap: 10px;
  align-items: center;
  padding-left: 20px;
}

.navigation-logout-arrow {
  margin-right: 10px;
  display: flex;
}

.navigation-logout-user-icon {
  width: 20px;
  height: 20px;
}

.navigation-research-titles {
  overflow-y: auto;
  height: 60vh;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.navigation-research-titles::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.survey-info-container {
  height: 173px;
  width: 100%;
  background-size: 830px;
  background-position-x: -341px;
  background-position-y: -152px;
  padding-left: 20px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.survey-info {
  color: #FFFFFF;
}

.survey-info-title {
  font-size: 20px;
  font-weight: 700;
}

.survey-info-subtitle {
  font-size: 14px;
  font-weight: 400;
}

.nav-toggle-icon {}

@media (min-width: 1024px) and (max-width: 1440px) {


}
</style>